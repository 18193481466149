export const SHOW_PROMPT = 'frontend/languages/SHOW_PROMPT';
export const CHANGE_LANGUAGE = 'frontend/languages/CHANGE_LANGUAGE';
export const initialState = {};
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_PROMPT:
      return action.payload;
    case CHANGE_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
}
