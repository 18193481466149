export const CART_MESSAGE = 'frontend/cart-message/CART_MESSAGE';
export const RESET_CART_MESSAGE = 'frontend/cart-message/RESET_CART_MESSAGE';
export const CART_MESSAGE_DEFAULT = 'NONE';
export const ITEM_UNAVAILABLE = 'ITEM_UNAVAILABLE';

const initialState = {
  astatus: CART_MESSAGE_DEFAULT,
  amessage: '',
  adata: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_CART_MESSAGE:
      return initialState;
    case CART_MESSAGE:
      return action.payload;
    default:
      return state;
  }
}
