
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

const FETCH_ADVERTS = 'frontend/adverts/FETCH_ADVERTS';

/**
 * Sort an array of adverts by the sort_order key
 * @param  {Array.Object} adverts
 * @return {Array.Object}
 */
const sortAdverts = (adverts = []) => {
  if (!adverts || !adverts.length) {
    return [];
  }

  return sortBy(adverts, ['sort_order']);
};

/**
 * Filter adverts based on user operating system
 * @param  {Array.Object} adverts
 * @return {Array.Object}
 */
const filterAdverts = (adverts = []) => {
  if (!adverts || !adverts.length) {
    return [];
  }

  let userOs = 'windows';

  if (get(navigator, 'appVersion', '').includes('Mac')) {
    userOs = 'mac';
  }

  if (get(navigator, 'appVersion', '').includes('Linux') || get(navigator, 'appVersion', '').includes('X11')) {
    userOs = 'linux';
  }

  return adverts.filter((advert) => {
    const platforms = get(advert, 'platforms', {});
    if (Object.keys(platforms).every(platform => !platforms[platform])) {
      return true;
    }

    return platforms[userOs];
  });
};

const advertTypes = [
  'Assistant',
  'v3-TopBackgroundSolid',
  'v3-TopBanner',
  'v3-TopBannerMobile',
  'v3-Carousel',
  'v3-StripBanner',
  'SlimBanner',
  'ProductFooterBanner',
  'TakeOver',
  'MidPageBanner',
  'GamesUnder',
];

const defaultAdverts = () => {
  const advertsByType = {};

  advertTypes.forEach((type) => {
    advertsByType[type] = [];
  });

  advertsByType.hasLoaded = false;

  return advertsByType;
};

/**
 * Split an array of adverts by the advert_type key
 * @param  {Array.Object} adverts
 * @return {Object}
 */
const splitAdverts = (adverts = []) => {
  const advertsByType = {};

  advertTypes.forEach((type) => {
    advertsByType[type] = sortAdverts(adverts.filter(advert => advert.advert_type === type));
  });

  advertsByType.hasLoaded = true;

  return advertsByType;
};


export default function reducer(state = defaultAdverts(), action) {
  switch (action.type) {
    case FETCH_ADVERTS:
      return splitAdverts(filterAdverts(action.payload));
    default:
      return state;
  }
}
