export const HAS_LOADED = 'frontend/recaptcha/HAS_LOADED';
export const HAS_V3_LOADED = 'frontend/recaptcha/HAS_V3_LOADED';

export const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case HAS_LOADED:
      return { ...state, loaded: true };
    case HAS_V3_LOADED:
      return { ...state, v3_loaded: true };
    default:
      return state;
  }
}
