export const RESET_ATOK = 'frontend/atok/RESET_ATOK';
export const NEW_ATOK = 'frontend/ratok/NEW_ATOK';

export const initialState = {
  value: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ATOK:
      return initialState;
    case NEW_ATOK:
      return action.payload;
    default:
      return state;
  }
}
