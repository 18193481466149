const REDEEM_PRODUCT_SEARCH = 'frontend/redeem/REDEEM_PRODUCT_SEARCH';
const REDEEM_PRODUCT_REDEEM = 'frontend/redeem/REDEEM_PRODUCT_REDEEM';
const REDEEM_PRODUCT_CANCEL = 'frontend/redeem/REDEEM_PRODUCT_CANCEL';
const NEWS_LETTER_STATUS = 'frontend/redeem/NEWS_LETTER_STATUS';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REDEEM_PRODUCT_SEARCH:
      return { ...state, ...action.payload, exists: true };
    case REDEEM_PRODUCT_REDEEM:
      return { ...state, ...action.payload, exists: false };
    case NEWS_LETTER_STATUS:
      return { ...state, redeemNewsletter: action.payload };
    case REDEEM_PRODUCT_CANCEL:
      return initialState;
    default:
      return state;
  }
}
