const SET_ANON_ID = 'frontend/anonymous/SET_ANON_ID';

export const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_ANON_ID:
      return { ...state, id: action.payload };
    default:
      return state;
  }
}

export function setAnonId(anonId) {
  return { type: SET_ANON_ID, payload: anonId };
}
