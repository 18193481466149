const SET_GIFT_ORDER_DATA = 'frontend/checkout/SET_GIFT_ORDER_DATA';

const initialStateCheckout = {
  giftOrder: {},
};

export default function reducer(state = initialStateCheckout, action) {
  switch (action.type) {
    case SET_GIFT_ORDER_DATA:
      return { ...state, giftOrder: action.payload };
    default:
      return state;
  }
}
