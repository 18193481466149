const AGE_GATING_SUCCESS = 'frontend/age-gating/AGE_GATING';
const AGE_GATING_FAIL = 'frontend/age-gating/AGE_GATING';

const initialState = {
  success: false,
  dob: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case AGE_GATING_SUCCESS:
      return { ...state, ...action.payload };
    case AGE_GATING_FAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
