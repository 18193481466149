const FETCH_MYSTERY = 'frontend/mystery/FETCH_MYSTERY';
const SHOW_UPSELL = 'frontend/mystery/SHOW_UPSELL';

const initialState = { all: [], shownUpsell: false };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_UPSELL:
      return { ...state, shownUpsell: true };
    case FETCH_MYSTERY:
      return { ...state, all: action.payload };
    default:
      return state;
  }
}
