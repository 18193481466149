export const RESET_ACCOUNT_CONFIRM = 'frontend/account-confirm/RESET_ACCOUNT_CONFIRM';
export const NEW_STATE_ACCOUNT_CONFIRM = 'frontend/account-confirm/NEW_STATE_ACCOUNT_CONFIRM';

export const initialState = {
  status: 0,
  error: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_ACCOUNT_CONFIRM:
      return initialState;
    case NEW_STATE_ACCOUNT_CONFIRM:
      return action.payload;
    default:
      return state;
  }
}
