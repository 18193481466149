export const FEEDBACK_POPUP = 'frontend/feedback/FEEDBACK_POPUP';
export const FEEDBACK_POPUP_REMOVE = 'frontend/feedback/FEEDBACK_POPUP_REMOVE';

export default function reducer(state = [], action) {
  switch (action.type) {
    case FEEDBACK_POPUP: {
      const popupData = action.payload;
      popupData.id = Date.now();
      return [...state, popupData];
    }
    case FEEDBACK_POPUP_REMOVE: {
      const id = action.payload;
      return [...state.filter(feedback => feedback.id !== id)];
    }
    default:
      return state;
  }
}
