export const NEW_OPPORTUNITIES = 'frontend/opportunities/NEW_OPPORTUNITIES';

export const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case NEW_OPPORTUNITIES:
      return action.payload;
    default:
      return state;
  }
}
