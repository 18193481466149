export const REQUEST_TWO_FACTOR = 'frontend/twoFactor/REQUEST_TWO_FACTOR';
export const DISABLE_TWO_FACTOR = 'frontend/twoFactor/DISABLE_TWO_FACTOR';
export const CANCEL_TWO_FACTOR = 'frontend/twoFactor/CANCEL_TWO_FACTOR';
export const TWO_FACTOR_ERROR = 'frontend/twoFactor/TWO_FACTOR_ERROR';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case TWO_FACTOR_ERROR:
      return { ...state, ...action.payload };
    case REQUEST_TWO_FACTOR:
      return action.payload;
    case DISABLE_TWO_FACTOR:
      return action.payload;
    case CANCEL_TWO_FACTOR:
      return {};
    default:
      return state;
  }
}
