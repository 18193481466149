export const CHECKOUT_MESSAGE = 'frontend/checkout-message/CHECKOUT_MESSAGE';
export const RESET_CHECKOUT_MESSAGE = 'frontend/checkout-message/RESET_CHECKOUT_MESSAGE';
export const CHECKOUT_MESSAGE_DEFAULT = 'NONE';
export const CHECKOUT_MESSAGE_REGION_LOCKED = 'REGION_LOCKED';
export const SOLD_OUT = 'SOLD_OUT';
export const BLOCKED_ISP = 'BLOCKED_ISP';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const STAR_DEAL_EXPIRED = 'STAR_DEAL_EXPIRED';
export const BASKET_REFRESHED = 'BASKET_REFRESHED';
export const checkoutMessagePaymentErrorText = 'Payment cannot be completed please contact our support department';
export const checkoutMessagePaymentErrorTextAgain = 'Payment cannot be completed please try again';

const initialState = {
  status: CHECKOUT_MESSAGE_DEFAULT,
  message: '',
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_CHECKOUT_MESSAGE:
      return initialState;
    case CHECKOUT_MESSAGE:
      return action.payload;
    default:
      return state;
  }
}
