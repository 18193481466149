export const EMAIL_INITIAL = 'frontend/email-subscribe/EMAIL_INITIAL';
export const EMAIL_SUBSCRIBE = 'frontend/email-subscribe/EMAIL_SUBSCRIBE';
export const EMAIL_ERROR = 'frontend/email-subscribe/EMAIL_ERROR';

export const initialState = {
  currentState: EMAIL_INITIAL,
  emailResponse: '',
  errorText: '',
  context: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EMAIL_INITIAL:
      return initialState;
    case EMAIL_SUBSCRIBE:
      return {
        ...state,
        currentState: EMAIL_SUBSCRIBE,
        emailResponse: action.payload.text,
        context: action.payload.context,
      };
    case EMAIL_ERROR:
      return {
        ...state,
        currentState: EMAIL_ERROR,
        errorText: action.payload.text,
        context: action.payload.context,
      };
    default:
      return state;
  }
}
