export const PUBLISHER_RANKS = 'frontend/publishers/PUBLISHER_RANKS';

export const initialState = {
  ranking: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case PUBLISHER_RANKS:
      return { ...state, ranking: action.payload };
    default:
      return state;
  }
}
