import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';

export const API_PAYMENT_RESET = 'frontend/apipayment/API_PAYMENT_RESET';
export const NEW_API_PAYMENT = 'frontend/apipayment/NEW_API_PAYMENT';
export const REMOVE_SAVED_CARD = 'frontend/apipayment/REMOVE_SAVED_CARD';

const initialState = null;

function doRemoveSavedCard(state, id) {
  const nowState = cloneDeep(state);
  const storedPaymentMethods = get(nowState, 'storedPaymentMethods', []);
  const foundIndex = storedPaymentMethods.findIndex(card => card.id === id);
  if (foundIndex >= 0) {
    storedPaymentMethods.splice(foundIndex, 1);
  }
  nowState.storedPaymentMethods = storedPaymentMethods;
  return nowState;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case API_PAYMENT_RESET:
      return initialState;
    case NEW_API_PAYMENT:
      return action.payload;
    case REMOVE_SAVED_CARD:
      return doRemoveSavedCard(state, action.payload);
    default:
      return state;
  }
}
