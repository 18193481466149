export const SET_DEVICE_FINGERPRINT = 'frontend/cart-message/SET_DEVICE_FINGERPRINT';
export const RESET_DEVICE_FINGERPRINT = 'frontend/cart-message/RESET_DEVICE_FINGERPRINT';

const initialState = '';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_DEVICE_FINGERPRINT:
      return initialState;
    case SET_DEVICE_FINGERPRINT:
      return action.payload;
    default:
      return state;
  }
}
