export const UNSUBSCRIBE_MESSAGE = 'frontend/cart-message/UNSUBSCRIBE_MESSAGE';
export const RESET_UNSUBSCRIBE_MESSAGE = 'frontend/cart-message/RESET_UNSUBSCRIBE_MESSAGE';
export const UNSUBSCRIBE_MESSAGE_DEFAULT = 'frontend/cart-message/UNSUBSCRIBE_MESSAGE_DEFAULT';
export const UNSUBSCRIBE_MESSAGE_SUCCESS = 'frontend/cart-message/UNSUBSCRIBE_MESSAGE_SUCCESS';
export const UNSUBSCRIBE_MESSAGE_ERROR = 'frontend/cart-message/UNSUBSCRIBE_MESSAGE_ERROR';

const initialState = {
  status: UNSUBSCRIBE_MESSAGE_DEFAULT,
  message: '',
  data: {},
};

const successState = {
  status: UNSUBSCRIBE_MESSAGE_SUCCESS,
  message: '',
  data: {},
};

const errorState = {
  status: UNSUBSCRIBE_MESSAGE_ERROR,
  message: '',
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_UNSUBSCRIBE_MESSAGE:
      return initialState;
    case UNSUBSCRIBE_MESSAGE:
      return action.payload;
    case UNSUBSCRIBE_MESSAGE_SUCCESS:
      return successState;
    case UNSUBSCRIBE_MESSAGE_ERROR:
      return errorState;
    default:
      return state;
  }
}
