export const ERROR_MESSAGE = 'frontend/error-messages/CART_ERROR_MESSAGE';
export const RESET_MESSAGE = 'frontend/error-messages/RESET_MESSAGE';

const initialState = {
  errorMsg: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return initialState;
    case ERROR_MESSAGE:
      return { ...state, errorMsg: action.payload };
    default:
      return state;
  }
}
