import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import env from '../env';
import { addVoucher } from './ducks/voucher';
import { doLoad, checkHasLoggedIn } from './ducks/initial-load';
import { fetchAnonWishlist, addToAnonWishlist } from './ducks/anon-wishlist';
import { fetchCart, doAddToCart, addAll } from './ducks/cart';
import { fetchWishlist, addToWishlistFromSlug } from './ducks/wishlist';
import { getNativeCurrencyRate } from './ducks/native-rates';
import { history } from './store';
import { newStateAtok } from './ducks/atok';
import { setAnonId } from './ducks/anonymous';
import GeneralUtils from '../Helpers/GeneralUtils';
import fetchAll from './ducks/all';
import getUrlParameter from '../Helpers/Url/GetUrlParameter';
import isBot from '../Helpers/SEO/is-bot';
import onTabActive from '../Helpers/OnTabActive';
import waitForActive from '../Helpers/WaitForActive';

const initialTimeout = 0;
const delayedLoadTimeout = 0;
const headerTimeout = 200;

let allInterval = 0;

export default function initialDataFetch(store) {
  window.setTimeout(() => {
    window.setTimeout(() => {
      if (!window.location.pathname.includes('/preview/')) {
        // Set up interval to do fetchAll every 5 minutes, but skip if window is in a background tab
        if (allInterval === 0) {
          allInterval = window.setInterval(() => {
            waitForActive('all', () => { fetchAll(store.dispatch); });
          }, env.isDevelopment ? 30000 : 300000);
        }
      }

      // Fetch cart when tab re-activated, to stop baskets getting out of sync
      onTabActive(60, () => {
        store.dispatch(fetchCart());
      });

      if (!window.location.pathname.endsWith('/cart')) {
        fetchAll(store.dispatch);
      }

      if (!isBot()) {
        store.dispatch(getNativeCurrencyRate());

        window.setTimeout(() => {
          // Bug where we put a function into this value. Clear it up before using it
          if (localStorage.getItem('wishlistSort') && localStorage.getItem('wishlistSort').length > 20) {
            localStorage.removeItem('wishlistSort');
          }

          newStateAtok(localStorage.getItem('bsatok') || null);

          const urlAnonId = getUrlParameter('set_anon_id');
          if (urlAnonId) {
            store.dispatch(setAnonId(urlAnonId));
            localStorage.setItem('exitIntentEmailCart', Date.now());
          }

          const referalCode = getUrlParameter('refer_a_friend');
          if (referalCode) {
            GeneralUtils.setReferalCode(referalCode);
          }

          const cartSlugData = getUrlParameter('add_to_basket');
          if (cartSlugData) {
            const cartTier = getUrlParameter('add_to_basket_tier');

            if (cartSlugData.includes(',')) {
              store.dispatch(addAll(cartSlugData.split(',')));
            } else {
              store.dispatch(doAddToCart(history.push, null, cartTier ? Number(cartTier) : 0, 'popup', cartSlugData));
            }
          } else {
            store.dispatch(fetchCart());
          }

          const voucherCode = getUrlParameter('add_voucher');
          if (voucherCode) {
            store.dispatch(addVoucher(voucherCode, getUrlParameter('ind'), true));
          }

          const state = store.getState();
          const siteBrand = get(state, 'siteBrand', 'fanatical');
          const curentVoucher = get(state, 'voucher.voucher', {});


          // PowerColor voucher check
          if (siteBrand === 'powercolor' && isEmpty(curentVoucher)) {
            store.dispatch(addVoucher('POWER5', getUrlParameter('ind'), true));
          }

          // Omen voucher check
          if (siteBrand === 'hpomen' && isEmpty(curentVoucher) && !window.location.pathname.includes('/game/') && !window.location.pathname.includes('/dlc/')) {
            store.dispatch(addVoucher('OMENVIP', getUrlParameter('ind'), true));
          }

          const authenticated = store.getState().auth.authenticated;
          const addToWishlistSlug = getUrlParameter('add_to_wishlist');

          if (addToWishlistSlug) {
            if (authenticated) store.dispatch(addToWishlistFromSlug(addToWishlistSlug));
            else addToAnonWishlist(addToWishlistSlug);
          }

          if (authenticated && !addToWishlistSlug) store.dispatch(fetchWishlist());
          if (!authenticated && !addToWishlistSlug) store.dispatch(fetchAnonWishlist());

          checkHasLoggedIn();
        }, headerTimeout);
      }

      doLoad();
    }, delayedLoadTimeout);
  }, initialTimeout);
}
