export const SWITCH_SITE_THEME = 'frontend/site-theme/SWITCH_SITE_THEME';

const initialState = localStorage.getItem('siteTheme') || 'dark';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SWITCH_SITE_THEME:
      return action.payload;
    default:
      return state;
  }
}
