export const FRANCHISE_RANKS = 'frontend/franchises/FRANCHISE_RANKS';

export const initialState = {
  franchiseRanks: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FRANCHISE_RANKS:
      return { ...state, franchiseRanks: action.payload };
    default:
      return state;
  }
}
