export const FLASH_SALE = 'frontend/sale/FLASH_SALE';
export const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FLASH_SALE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
