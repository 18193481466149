export const REDEEM_CODE_ERROR_RESET =
  'frontend/verify-payment-error/REDEEM_CODE_ERROR_RESET';
export const REDEEM_CODE_ERROR_SET =
  'frontend/verify-payment-error/REDEEM_CODE_ERROR_SET';
export const initialStateRedeemCodeError = {
  status: 0,
  error: '',
};
export default function reducer(state = initialStateRedeemCodeError, action) {
  switch (action.type) {
    case REDEEM_CODE_ERROR_RESET:
      return initialStateRedeemCodeError;
    case REDEEM_CODE_ERROR_SET:
      return action.payload;
    default:
      return state;
  }
}
