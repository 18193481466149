export const RESET_PASSWORD_RESET = 'frontend/password-reset/RESET_PASSWORD_RESET';
export const NEW_STATE_PASSWORD_RESET = 'frontend/password-reset/NEW_STATE_PASSWORD_RESET';

export const initialState = {
  status: 0,
  error: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_PASSWORD_RESET:
      return initialState;
    case NEW_STATE_PASSWORD_RESET:
      return action.payload;
    default:
      return state;
  }
}
