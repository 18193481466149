import env from '../../env';

export const TRUSTPILOT_API = 'frontend/trustpilot-api/TRUSTPILOT_API';

export const initialState = {
  total: env.trustpilot.total,
  score: {
    stars: env.trustpilot.stars,
    trustScore: env.trustpilot.trustScore,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TRUSTPILOT_API:
      return action.payload;
    default:
      return state;
  }
}
