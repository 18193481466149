const SPLIT_TEST = 'frontend/split-test/SPLIT_TEST';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case SPLIT_TEST:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
