export const RESET_URL = 'frontend/epic/RESET_URL';
export const NEW_URL = 'frontend/epic/NEW_URL';

const initialStateEpic = {
  url: '',
};

export default function reducer(state = initialStateEpic, action) {
  switch (action.type) {
    case RESET_URL:
      return initialStateEpic;
    case NEW_URL:
      return action.payload;
    default:
      return state;
  }
}
