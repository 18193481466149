export const MEGA_MENU_PRODUCTS = 'frontend/mega-menu-products/MEGA_MENU_PRODUCTS';

export const initialState = {
  products: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case MEGA_MENU_PRODUCTS:
      return { ...state, products: action.payload };
    default:
      return state;
  }
}
