export const SELECTABLE_FREE_PRODUCTS = 'frontend/product/SELECTABLE_FREE_PRODUCTS';
export const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECTABLE_FREE_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
}
