export const GOOGLE_INITIAL = 'frontend/google/GOOGLE_INITIAL';
export const GOOGLE_SUCCESS = 'frontend/google/GOOGLE_SUCCESS';
export const GOOGLE_ERROR = 'frontend/google/GOOGLE_ERROR';

export const initialState = {
  status: GOOGLE_INITIAL,
  googleLoginError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOOGLE_INITIAL:
      return initialState;
    case GOOGLE_SUCCESS:
      return { ...state, status: GOOGLE_SUCCESS, googleErrorText: '' };
    case GOOGLE_ERROR:
      return { ...state, status: GOOGLE_ERROR, googleLoginError: action.payload };
    default:
      return state;
  }
}
