
export const FACEBOOK_INITIAL = 'frontend/facebook/FACEBOOK_INITIAL';
export const FACEBOOK_SUCCESS = 'frontend/facebook/FACEBOOK_SUCCESS';
export const FACEBOOK_ERROR = 'frontend/facebook/FACEBOOK_ERROR';

export const initialState = {
  status: FACEBOOK_INITIAL,
  errorText: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FACEBOOK_INITIAL:
      return initialState;
    case FACEBOOK_SUCCESS:
      return { ...state, status: FACEBOOK_SUCCESS, errorText: '' };
    case FACEBOOK_ERROR:
      return { ...state, status: FACEBOOK_ERROR, errorText: action.payload };
    default:
      return state;
  }
}
