export const GET_NATIVE_RATES = 'frontend/native-rates/GET_NATIVE_RATES';
export const REMOVE_NATIVE_RATES = 'frontend/native-rates/REMOVE_NATIVE_RATES';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case REMOVE_NATIVE_RATES:
      return {};
    case GET_NATIVE_RATES:
      return action.payload;
    default:
      return state;
  }
}
