const GET_NOTIFICATIONS = 'frontend/notifications/GET_NOTIFICATIONS';

const initialState = { count: 0, notifications: [] };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      if (action.payload) {
        return {
          ...state,
          count: action.payload.count,
          notifications: action.payload.notifications,
        };
      }
      return state;
    default:
      return state;
  }
}
