const COOKIE_OPEN = 'frontend/cookie/COOKIE_OPEN';
const COOKIE_CONSENT = 'frontend/cookie/COOKIE_CONSENT';
const COOKIE_RESET = 'frontend/cookie/COOKIE_RESET';

export const initialState = {
  open: false,
  consent: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COOKIE_OPEN:
      return { ...state, open: action.payload };
    case COOKIE_CONSENT:
      window.Cookiebot = action.payload;
      return { ...state, consent: action.payload };
    case COOKIE_RESET:
      window.Cookiebot = {};
      return { ...state, consent: {} };
    default:
      return state;
  }
}
