export const RESET_REDEEM_CONFIRM = 'frontend/redeem-confirm/RESET_REDEEM_CONFIRM';
export const NEW_STATE_REDEEM_CONFIRM = 'frontend/redeem-confirm/NEW_STATE_REDEEM_CONFIRM';

export const initialState = {
  status: 0,
  error: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_REDEEM_CONFIRM:
      return initialState;
    case NEW_STATE_REDEEM_CONFIRM:
      return action.payload;
    default:
      return state;
  }
}
