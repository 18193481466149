export const DO_LOAD = 'frontend/initial/DO_LOAD';
export const CHECK_HAS_LOGGED_IN = 'frontend/initial/CHECK_HAS_LOGGED_IN';

export const initialState = {
  load: false,
  hasLoggedIn: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DO_LOAD:
      return { ...state, load: true };
    case CHECK_HAS_LOGGED_IN:
      return { ...state, hasLoggedIn: action.payload };
    default:
      return state;
  }
}
