export const UNIVERSAL_ERROR_RESET =
  'frontend/universal-error/UNIVERSAL_ERROR_RESET';
export const UNIVERSAL_ERROR_SET =
  'frontend/universal-error/UNIVERSAL_ERROR_SET';
export const initialStateUniversalError = {
  status: '',
  error: '',
};
export default function reducer(state = initialStateUniversalError, action) {
  switch (action.type) {
    case UNIVERSAL_ERROR_RESET:
      return initialStateUniversalError;
    case UNIVERSAL_ERROR_SET:
      return action.payload;
    default:
      return state;
  }
}
