const FETCH_DELIVERIES = 'frontend/deliveries/FETCH_DELIVERIES';

const initialState = { all: [] };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DELIVERIES:
      return { ...state, all: action.payload };
    default:
      return state;
  }
}
