export const SET_USER_LIBRARY = 'frontend/user-traits/SET_USER_LIBRARY';

const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_LIBRARY:
      return action.payload;
    default:
      return state;
  }
}
