export const LIVE_DISPLAY_TYPES = 'frontend/live-display-types/LIVE_DISPLAY_TYPES';

export const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIVE_DISPLAY_TYPES:
      return action.payload;
    default:
      return state;
  }
}
