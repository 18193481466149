
export const FANDOM_INITIAL = 'frontend/fandom/FANDOM_INITIAL';
export const FANDOM_SUCCESS = 'frontend/fandom/FANDOM_SUCCESS';
export const FANDOM_ERROR = 'frontend/fandom/FANDOM_ERROR';

export const initialState = {
  status: FANDOM_INITIAL,
  errorText: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FANDOM_INITIAL:
      return initialState;
    case FANDOM_SUCCESS:
      return { ...state, status: FANDOM_SUCCESS, errorText: '' };
    case FANDOM_ERROR:
      return { ...state, status: FANDOM_ERROR, errorText: action.payload };
    default:
      return state;
  }
}
