export const FREE_PRODUCTS = 'frontend/product/FREE_PRODUCTS';
export const initialState = [];

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FREE_PRODUCTS:
      return action.payload;
    default:
      return state;
  }
}
