const FETCH_PICK_AND_MIXES = 'frontend/pickAndMix/FETCH_PICK_AND_MIXES';

const initialState = { all: [] };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PICK_AND_MIXES:
      return { ...state, all: action.payload };
    default:
      return state;
  }
}
