
const BUNDLE_BLAST = 'frontend/bundleblast/BUNDLE_BLAST';
const BUNDLE_COUNT = 'frontend/bundleblast/BUNDLE_COUNT';
const BUNDLE_LATEST = 'frontend/bundleblast/BUNDLE_LATEST';

const initialState = {
  bundleCount: 0,
  bundle: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case BUNDLE_BLAST:
      return { ...state, bundleTime: action.payload };
    case BUNDLE_COUNT:
      return { ...state, bundleCount: action.payload.total };
    case BUNDLE_LATEST:
      return Object.assign({}, state, {
        bundle: action.payload,
      });
    default:
      return state;
  }
}
