import get from 'lodash/get';

const CREATE_ORDER = 'frontend/checkout/CREATE_ORDER';
const CANCEL_ORDER = 'frontend/checkout/CANCEL_ORDER';
const CANCEL_ORDER_FAIL = 'frontend/checkout/CANCEL_ORDER_FAIL';
const RESET_CHECKOUT = 'frontend/checkout/RESET_CHECKOUT';
const BILLING_DETAILS_REQUESTED = 'frontend/checkout/BILLING_DETAILS_REQUESTED';

const initialStateCheckout = {
  order: {},
  userId: undefined,
  pageId: undefined,
  countryCode: undefined,
  billingFields: [],
  payment: {},
};

export default function reducer(state = initialStateCheckout, action) {
  switch (action.type) {
    case CREATE_ORDER:
      return {
        order: get(action, 'payload.order', initialStateCheckout.order),
        userId: get(action, 'payload.userId', initialStateCheckout.userId),
        pageId: get(action, 'payload.pageId', initialStateCheckout.pageId),
        countryCode: get(action, 'payload.countryCode', initialStateCheckout.countryCode),
        payment: get(action, 'payload.payment', initialStateCheckout.payment),
        billingFields: get(action, 'payload.billingFields', state.billingFields),
      };
    case CANCEL_ORDER:
      return initialStateCheckout;
    case CANCEL_ORDER_FAIL:
      return state;
    case RESET_CHECKOUT:
      return initialStateCheckout;
    case BILLING_DETAILS_REQUESTED:
      return { ...state, billingFields: action.payload };
    default:
      return state;
  }
}
