const FETCH_BUNDLE_REMINDER = 'frontend/publishers/FETCH_BUNDLE_REMINDER';
const SAVE_BUNDLE_REMINDER = 'frontend/publishers/SAVE_BUNDLE_REMINDER';

const initialState = { active: false };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_BUNDLE_REMINDER:
      return { ...state, ...action.payload };
    case SAVE_BUNDLE_REMINDER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
