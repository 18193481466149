const FETCHED_RECOMMENDATIONS = 'frontend/product/FETCHED_RECOMMENDATIONS';

const initialState = { recommendations: [] };

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCHED_RECOMMENDATIONS:
      return { ...state, recommendations: action.payload };
    default:
      return state;
  }
}
