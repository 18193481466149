const FETCH_STAR_DEAL = 'frontend/products/FETCH_STAR_DEAL';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_STAR_DEAL:
      return action.payload;
    default:
      return state;
  }
}
