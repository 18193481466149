export const RESET_ACCOUNT_PREFERENCE_CENTRE = 'frontend/account-preference/RESET_ACCOUNT_PREFERENCE_CENTRE';
export const NEW_ACCOUNT_PREFERENCE_CENTRE = 'frontend/account-preference/NEW_ACCOUNT_PREFERENCE_CENTRE';
export const NEW_ACCOUNT_LISTS = 'frontend/account-preference/NEW_ACCOUNT_LISTS';
export const SAVE_LISTS = 'frontend/account-preference/SAVE_LISTS';

export const initialPreferencesState = {
  preferences: [],
  accountLists: [],
  listSaveError: null,
};

export default function reducer(state = initialPreferencesState, action) {
  switch (action.type) {
    case RESET_ACCOUNT_PREFERENCE_CENTRE:
      return initialPreferencesState;
    case NEW_ACCOUNT_PREFERENCE_CENTRE:
      return { ...state, preferences: action.payload };
    case NEW_ACCOUNT_LISTS:
      return { ...state, accountLists: action.payload };
    case SAVE_LISTS:
      return { ...state, listSaveError: action.payload };
    default:
      return state;
  }
}
