import _get from 'lodash/get';

const ADD_VOUCHER = 'frontend/voucher/ADD_VOUCHER';
const REMOVE_VOUCHER = 'frontend/voucher/REMOVE_VOUCHER';
const RESET_VOUCHER = 'frontend/voucher/RESET_VOUCHER';

const initialStateVoucher = {
  voucher: {},
  voucherText: '',
};

export default function reducer(state = initialStateVoucher, action) {
  switch (action.type) {
    case ADD_VOUCHER: {
      const voucher = _get(action, 'payload.voucher', initialStateVoucher.voucher);

      if (voucher.code) {
        voucher.expiry = new Date(Date.now() + (60 * 60 * 24 * 1000)).toISOString();
      }
      return {
        voucher,
        voucherText: _get(action, 'payload.voucherText', initialStateVoucher.voucherText),
      };
    }
    case RESET_VOUCHER:
      return initialStateVoucher;
    case REMOVE_VOUCHER:
      return initialStateVoucher;
    default:
      return state;
  }
}
