const FETCH_DEALS_COUNT = 'frontend/products/FETCH_DEALS_COUNT';

export const initialState = {
  lastDayDealsCount: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DEALS_COUNT:
      if (action.payload) {
        return { ...state, lastDayDealsCount: action.payload.total };
      }
      return state;
    default:
      return state;
  }
}
