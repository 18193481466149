const FETCH_VOUCHERS = 'frontend/vouchers/FETCH_VOUCHERS';
const VOUCHER_UPSELL = 'frontend/vouchers/VOUCHER_UPSELL';
const VOUCHER_SPEND_MORE = 'frontend/vouchers/VOUCHER_SPEND_MORE';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case FETCH_VOUCHERS:
      if (action.payload) {
        return { ...state, all: action.payload };
      }
      return state;
    case VOUCHER_UPSELL:
      return { ...state, voucherUpsell: action.payload };
    case VOUCHER_SPEND_MORE:
      return { ...state, voucherSpendMore: action.payload };
    default:
      return state;
  }
}
