
const FETCH_WISHLIST = 'frontend/wishlist/FETCH_WISHLIST';
const FETCH_MORE = 'frontend/wishlist/FETCH_MORE';
const UPDATE_WISHLIST_ITEM = 'frontend/wishlist/UPDATE_WISHLIST_ITEM';
const EMPTY_WISHLIST = 'frontend/wishlist/EMPTY_WISHLIST';
const GET_WISHLIST_ON_SALE = 'frontend/wishlist/GET_WISHLIST_ON_SALE';
const GET_WISHLIST_FRIENDS = 'frontend/wishlist/GET_WISHLIST_FRIENDS';
const WISHLIST_ERROR = 'frontend/wishlist/WISHLIST_ERROR';
const WISHLIST_SUCCESS = 'frontend/wishlist/WISHLIST_SUCCESS';

const initialState = {
  count: 0,
  items: [],
  allSlugs: [],
  wishlistLoaded:
  false,
  friends: [],
  onSaleNbHits: 0,
  onSaleWishlist: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_WISHLIST_ITEM:
      return Object.assign({}, state, {
        activeItem: action.payload,
      });
    case FETCH_WISHLIST:
      return Object.assign({}, state, {
        count: action.payload.count,
        items: action.payload.items,
        wishlistLoaded: action.payload.wishlistLoaded,
        page: action.payload.page,
        nbPages: action.payload.nbPages,
        allSlugs: action.payload.allSlugs,
      });
    case FETCH_MORE:
      return Object.assign({}, state, {
        count: action.payload.count,
        items: state.items ? [...state.items, ...action.payload.items] : action.payload.items,
        wishlistLoaded: action.payload.wishlistLoaded,
        page: action.payload.page,
        nbPages: action.payload.nbPages,
      });
    case EMPTY_WISHLIST:
      return Object.assign({}, state, {
        count: 0,
        allSlugs: [],
        items: [],
      });
    case GET_WISHLIST_ON_SALE:
      return Object.assign({}, state, {
        onSaleWishlist: action.payload.hits,
        onSaleNbHits: action.payload.nbHits,
      });
    case GET_WISHLIST_FRIENDS:
      return Object.assign({}, state, {
        friends: action.payload,
      });
    case WISHLIST_ERROR:
      return Object.assign({}, state, {
        error: action.payload,
      });
    case WISHLIST_SUCCESS:
      return Object.assign({}, state, {
        success: action.payload,
      });
    default:
      return state;
  }
}
