const EXIT_INTENT_STAR_DEAL_STATE = 'frontend/exit-intent/EXIT_INTENT_STAR_DEAL_STATE';
const EXIT_INTENT_EMAIL_CART_STATE = 'frontend/exit-intent/EXIT_INTENT_EMAIL_CART_STATE';
const EXIT_INTENT_FANDOM_STATE = 'frontend/exit-intent/EXIT_INTENT_FANDOM_STATE';

const initialState = {
  leftBrowserStarDeal: false,
  leftBrowserEmailCart: false,
  leftBrowserFandom: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case EXIT_INTENT_STAR_DEAL_STATE:
      return { ...state, leftBrowserStarDeal: action.payload };
    case EXIT_INTENT_EMAIL_CART_STATE:
      return { ...state, leftBrowserEmailCart: action.payload };
    case EXIT_INTENT_FANDOM_STATE:
      return { ...state, leftBrowserFandom: action.payload };
    default:
      return state;
  }
}
