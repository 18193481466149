const SET_WISHLIST_ACTION = 'frontend/login-actions/SET_WISHLIST_ACTION';
const SET_WISHLIST_URL_ACTION = 'frontend/login-actions/SET_WISHLIST_URL_ACTION';
const SET_INDI_VOUCHER_ACTION = 'frontend/login-actions/SET_INDI_VOUCHER_ACTION';

const initialState = {};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_WISHLIST_ACTION:
      return Object.assign({}, state, { wishlistItem: action.payload });
    case SET_WISHLIST_URL_ACTION:
      return Object.assign({}, state, { wishlistItemSlug: action.payload });
    case SET_INDI_VOUCHER_ACTION:
      return Object.assign({}, state, {
        indiVoucher: action.payload.code,
      });
    default:
      return state;
  }
}
