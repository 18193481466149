export const SET_USER_TRAITS = 'frontend/user-traits/SET_USER_TRAITS';

const initialState = null;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_TRAITS:
      return action.payload;
    default:
      return state;
  }
}
