export const OPTIN_MESSAGE = 'frontend/cart-message/OPTIN_MESSAGE';
export const RESET_OPTIN_MESSAGE = 'frontend/cart-message/RESET_OPTIN_MESSAGE';
export const OPTIN_MESSAGE_DEFAULT = 'OPTIN_MESSAGE_DEFAULT';
export const OPTIN_MESSAGE_SUCCESS = 'OPTIN_MESSAGE_SUCCESS';
export const OPTIN_MESSAGE_ERROR = 'OPTIN_MESSAGE_ERROR';

const initialState = {
  astatus: OPTIN_MESSAGE_DEFAULT,
  amessage: '',
  adata: {},
};

const successState = {
  status: OPTIN_MESSAGE_SUCCESS,
  message: '',
  data: {},
};

const errorState = {
  status: OPTIN_MESSAGE_ERROR,
  message: '',
  data: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_OPTIN_MESSAGE:
      return initialState;
    case OPTIN_MESSAGE:
      return action.payload;
    case OPTIN_MESSAGE_SUCCESS:
      return successState;
    case OPTIN_MESSAGE_ERROR:
      return errorState;
    default:
      return state;
  }
}
