export const SWITCH_SITE_BRAND = 'frontend/site-theme/SWITCH_SITE_BRAND';

const initialState = localStorage.getItem('siteBrand') || 'fanatical';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SWITCH_SITE_BRAND:
      return action.payload;
    default:
      return state;
  }
}
