export const ADD_TO_RECENTLY_VIEWED = 'frontend/recently-viewed/ADD_TO_RECENTLY_VIEWED';
export const FETCH_RECENT_VIEWED = 'frontend/recently-viewed/FETCH_RECENT_VIEWED';

export const initialState = {
  recentlyViewedPersisted: [],
  recentlyViewedHits: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_TO_RECENTLY_VIEWED:
      return { ...state, recentlyViewedPersisted: action.payload };
    case FETCH_RECENT_VIEWED:
      return { ...state, recentlyViewedHits: action.payload };
    default:
      return state;
  }
}
