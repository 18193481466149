export const CHECKOUT_EMAIL_SIGNUP = 'frontend/checkout-email-signup/CHECKOUT_EMAIL_SIGNUP';
export const RESET_CHECKOUT_EMAIL_SIGNUP = 'frontend/checkout-email-signup/RESET_CHECKOUT_EMAIL_SIGNUP';

const initialState = false;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CHECKOUT_EMAIL_SIGNUP:
      return true;
    case RESET_CHECKOUT_EMAIL_SIGNUP:
      return initialState;
    default:
      return state;
  }
}
