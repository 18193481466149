export const TOGGLE_MODAL = 'frontend/modal/TOGGLE_MODAL';
export const CLOSE_MODAL = 'frontend/modal/CLOSE_MODAL';
export const OPEN_MODAL = 'frontend/modal/OPEN_MODAL';
export const LOGIN_MODAL = 'frontend/modal/LOGIN_MODAL';
export const RESET_MODAL = 'frontend/modal/RESET_MODAL';
export const WELCOME_MODAL = 'frontend/modal/WELCOME_MODAL';
export const REDEEM_REQUEST_MODAL = 'frontend/modal/REDEEM_REQUEST_MODAL';
export const WISHLIST_MODAL = 'frontend/modal/WISHLIST_MODAL';
export const WISHLIST_EMPTY_MODAL = 'frontend/modal/WISHLIST_EMPTY_MODAL';
export const CHECKOUT_MODAL = 'frontend/modal/CHECKOUT_MODAL';
export const STEAM_MODAL = 'frontend/modal/STEAM_MODAL';
export const STEAM_EMAIL_MODAL = 'frontend/modal/STEAM_EMAIL_MODAL';
export const UNIVERSAL_ERROR_MODAL = 'frontend/modal/UNIVERSAL_ERROR_MODAL';
export const UBISOFT_MODAL = 'frontend/modal/UBISOFT_MODAL';
export const ORIGIN_MODAL = 'frontend/modal/ORIGIN_MODAL';
export const STEAM_WISHLIST_MODAL = 'frontend/modal/STEAM_WISHLIST_MODAL';
export const LANGUAGE_MODAL = 'frontend/modal/LANGUAGE_MODAL';
export const EXIT_INTENT_MODAL = 'frontend/modal/EXIT_INTENT_MODAL';
export const TWO_FACTOR_MODAL = 'frontend/modal/TWO_FACTOR_MODAL';
export const REGIONAL_RESTRICTIONS_MODAL = 'frontend/modal/REGIONAL_RESTRICTIONS_MODAL';
export const SELECTABLE_FREE_PRODUCT_MODAL = 'frontend/modal/SELECTABLE_FREE_PRODUCT_MODAL';
export const PAY_WHAT_YOU_WANT_MODAL = 'frontend/modal/PAY_WHAT_YOU_WANT_MODAL';
export const GIFT_MODAL = 'frontend/modal/GIFT_MODAL';
export const WISHLIST_SHARE = 'frontend/modal/WISHLIST_SHARE';
export const SIMPLE_MODAL = 'frontend/modal/SIMPLE_MODAL';
export const SUPPORTED_LANGUAGES_MODAL = 'frontend/modal/SUPPORTED_LANGUAGES_MODAL';
export const USER_REVIEW_MODAL = 'frontend/modal/USER_REVIEW_MODAL';
export const GIVEAWAY_EMAIL_REQUIRED_MODAL = 'frontend/modal/GIVEAWAY_EMAIL_REQUIRED_MODAL';
export const PAY_WHAT_YOU_WANT_MODAL_DATA = 'frontend/modal/PAY_WHAT_YOU_WANT_MODAL_DATA';
export const REGIONAL_RESTRICTIONS_MODAL_DATA = 'frontend/modal/REGIONAL_RESTRICTIONS_MODAL_DATA';
export const SELECTABLE_FREE_PRODUCT_MODAL_DATA = 'frontend/modal/SELECTABLE_FREE_PRODUCT_MODAL_DATA';
export const GIFT_MODAL_DATA = 'frontend/modal/GIFT_MODAL_DATA';
export const WISHLIST_SHARE_DATA = 'frontend/modal/WISHLIST_SHARE_DATA';
export const SIMPLE_MODAL_DATA = 'frontend/modal/SIMPLE_MODAL_DATA';
export const SUPPORTED_LANGUAGES_MODAL_DATA = 'frontend/modal/SUPPORTED_LANGUAGES_MODAL_DATA';
export const USER_REVIEW_MODAL_DATA = 'frontend/modal/USER_REVIEW_MODAL_DATA';
export const GIVEAWAY_EMAIL_REQUIRED_MODAL_DATA = 'frontend/modal/GIVEAWAY_EMAIL_REQUIRED_MODAL_DATA';
export const EMAIL_SUBSCRIBE_MODAL = 'frontend/modal/EMAIL_SUBSCRIBE_MODAL';
export const WEB_PUSH_SUBSCRIBE_MODAL = 'frontend/modal/WEB_PUSH_SUBSCRIBE_MODAL';

export default function reducer(state = { page: '' }, action) {
  switch (action.type) {
    case TOGGLE_MODAL:
      return {
        ...state,
        [action.payload.name]: !state[action.payload.name],
        page: action.payload.page,
        [`${action.payload.name}_DATA`]: action.payload.data,
      };
    case CLOSE_MODAL:
      return { ...state, [action.payload]: false };
    case OPEN_MODAL:
      return { ...state, [action.payload.name]: true, page: action.payload.page };
    default:
      return state;
  }
}
