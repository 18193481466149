export const RESET_UBISOFT_ERROR = 'frontend/ubisoft-error/RESET_UBISOFT_ERROR';
export const NEW_STATE_UBISOFT_ERROR = 'frontend/ubisoft-error/NEW_STATE_UBISOFT_ERROR';

export const initialState = {
  status: 0,
  error: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET_UBISOFT_ERROR:
      return initialState;
    case NEW_STATE_UBISOFT_ERROR:
      return action.payload;
    default:
      return state;
  }
}
